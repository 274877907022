<template>
    <PageSection
        :title="title || defaultHeader"
        :description="description || ''"
        :viewAll="viewAllUpdated"
    >
        <ResourcesGrid
            :topic="category"
            v-bind="{ subcategory, max }"
            @fetch-complete="updateViewAll"
        >
            <template v-slot:emptyState>
                <EmptyState
                    card
                    :title="emptyState.title"
                    :description="emptyState.description"
                />
            </template>
        </ResourcesGrid>
    </PageSection>
</template>

<script>
import PageSection from '@/components/shared/layout/PageSection.vue';
import EmptyState from '@/components/shared/layout/EmptyState.vue';
import ResourcesGrid from '@/components/resources/ResourcesGrid.vue';

import createPayload from '@/mixins/data/createPayload';

export default {
    name: 'OrgResourceRow',
    mixins: [createPayload],
    components: {
        EmptyState,
        PageSection,
        ResourcesGrid,
    },
    props: {
        category: [Array, String],
        title: String,
        description: String,
        viewAll: Object,
        subcategory: String,
        selfView: Boolean,
    },
    data() {
        return {
            viewAllUpdated: this.viewAll,
        };
    },
    computed: {
        defaultHeader() {
            return this.selfView ? 'My Organization\'s Content' : 'Organization Content';
        },
        orgResources() {
            const key = this.subcategory ? `organization_assets_${this.subcategory}` : 'organization_assets';
            return this.$store.state.page[key];
        },
        assetKey() {
            if (this.subcategory) {
                return `${this.category}_assets_${this.subcategory}`;
            }
            return `${this.category}_assets`;
        },
        resourceQueue() {
            if (!this.$store.state.app.queue[this.assetKey]) {
                return [];
            }
            return this.$store.state.app.queue[this.assetKey];
        },
        emptyState() {
            if (this.title) {
                return {
                    title: `There is no content yet for ${this.title}.`,
                    description: 'When your organization has content uploaded, it will appear here.',
                };
            }

            if (!this.selfView) {
                return {
                    title: 'There is no content yet for this organization.',
                    description: 'When this organization has content uploaded, it will appear here.',
                };
            }

            return {
                title: 'There is no content yet for your organization.',
                description: 'When your organization has content uploaded, it will appear here.',
            };
        },
        max() {
            if (this.subcategory !== 'general' && this.viewAll.showAllOnPage !== true) {
                return 4;
            }

            return 30;
        },
        appMode() {
            return this.$store.state.app.mode;
        },
    },
    methods: {
        updateViewAll() {
            let show = false;
            if (!this.viewAll) {
                return;
            }

            show = this.resourceQueue.length > 0 ? this.viewAll.show : false;

            this.viewAllUpdated = {
                show,
                link: this.viewAll.link,
            };
        },
    },
};
</script>
